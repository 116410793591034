import styled from 'styled-components'

export const MainStyled = styled.main`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    // here to make sure children can take-up 100% of the height if desired
    display: flex; 
    flex-grow: 1;
    flex-direction: column;

    /* set min-height dynamically if required */
`;
