import { createGlobalStyle } from 'styled-components';
import rift from '../assets/fonts/Rift-Bold.otf';
import riftRegular from '../assets/fonts/Rift-Regular.otf';

const TypographyStyles = createGlobalStyle`

    :root {
        --rift: Rift, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        --rift-regular: Rift-Regular, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    @font-face {
        font-family: 'Rift';
        font-style: normal;
        font-weight: bold;
        src: url(${rift});
    }

    @font-face {
        font-family: 'Rift-Regular';
        font-style: normal;
        font-weight: normal;
        src: url(${riftRegular});
    }

    html {
        font-size: 10px;
    }

    h1 {
        font-size: 3.8rem;
        line-height: 5.0rem;
        font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
        color: var(--text-gray-dark);
    }

    h2 {
        font-size: 2.8rem;
        line-height: 3.2rem;
        font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
        color: var(--text-gray-dark);
    }

    h3 {
        font-family: Rift, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 2.4rem;
        line-height: 2.8rem;
        color: var(--text-gray-dark);
    }

    // 1025px or less (tablet/mobile)
    @media (max-width: 1025px) {
        h1 {
            font-size: 3.2rem;
        }

        h2 {
            font-size: 2.6rem;
        }
    }

    // 600px or less (tablet/mobile)
    @media (max-width: 600px) {


    }

`;

export default TypographyStyles;