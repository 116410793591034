import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/SendOutlined';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StaticImage } from "gatsby-plugin-image"

const BannerStyled = styled.div`
    --font-size: 1.4rem;
    background-color: var(--accent-green);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    .newsletter-narrow {
        display: none;
    }

    .newsletter-text {
        padding: 0px 40px;
        h1 {
            margin: 0;
            color: white;
            font-weight: var(--regular);
            font-size: 2.6rem;
        }

        h2 {
            margin: 0;
            font-weight: var(--light);
            color: white;
            font-size: 1.8rem;
            line-height: 2rem;
        }

        @media (max-width: 1025px) {
            padding: 0px 20px;
            h1 {
                font-size: 2.2rem;
                line-height: 3.2rem;
            }
            h2 {
                font-size: 1.6rem;
            }
        }
    }

    @media (max-width: 500px) {
        .newsletter-wide {
            display: none;
        }

        .newsletter-narrow {
            display: initial;
        }

        .newsletter-text {
            display: none;
        }
        
        .newsletter-can {
            margin-right: 20px;
        }

        h1 {
            line-height: 3rem;
            font-size: 2rem;
        }
        
        h2 {
            font-size: 1.4rem;
        }
    }
`;

const theme = createTheme({
    typography: {
      // Tell Material-UI what the font-size on the html element is.
      htmlFontSize: 10,
    },
    palette: {
        primary: {
            main: '#42855E',
        },
        secondary: {
            main: '#AFAFAF'
        }
    }
  }
);

const SubscribeModal = ({ open, handleClose }) => {
    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted={true}>
                <div className="ml-form-embed" style={{ display: `${open ? 'block' : 'none'}` }}
                    data-account="3617015:m9w3v1h1o2"
                    data-form="5091578:f1d3c9">
                </div>
            </Dialog>
        </>
    );
}

// const SubscribeModal = ({ open, handleClose }) => {
//     const [error, setError] = useState('');
//     const [email, setEmail] = useState('');
//     const [submitError, setSubmitError] = useState(false);
//     const [success, setSuccess] = useState(false);
//     const emailValidation = useRef((new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)));
    
//     useEffect(() => {
//         setEmail('');
//         setError('');
//         setSubmitError(false);
//         setSuccess(false)
//     }, [open]);

//     const handleChange = e => {
//         setEmail(e.currentTarget.value);
//     };
    
//     const handleSubmit = () => {
//         if (emailValidation.current.test(email)) {
//             fetch(`${process.env.GATSBY_NEWSLETTER_API}/subscribe`, 
//                 { 
//                     method: 'POST',
//                     body: JSON.stringify({ email }),
//                 }
//             ).then(res => {
//                 if (res.ok) {
//                     setSuccess(true);
//                     setTimeout(handleClose, 3000);
//                 } else {
//                     throw new Error();
//                 }
//             }).catch(() => {
//                 setSubmitError(true);
//             })
//         } else {
//             setError('Please enter a valid e-mail address');
//         }
//     };

//     return (
//         <ThemeProvider theme={theme}>
//             <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
//                 { success
//                 ?
//                     <DialogContent>
//                         <p className="material-icons" style={{
//                             color: 'var(--accent-green)',
//                             textAlign: 'center',
//                             width: '100%',
//                             fontSize: '60px',
//                             margin: '0px',
//                             padding: '0px'
//                         }}>check_circle</p>
//                         <p style={{
//                             width: '100%',
//                             textAlign: 'center',
//                             fontSize: '1.6rem',
//                         }}>Success! Check your e-mail to confirm your subscription.</p>
//                     </DialogContent>
//                 :
//                     <>
//                         <DialogTitle id="form-dialog-title">Subscribe to Newsletter</DialogTitle>
//                         <DialogContent>
//                             <DialogContentText>
//                                 Please enter your email address to receive the latest news, events, and discounts. We will send newsletters
//                                 occasionally and you can unsubscribe at any time.
//                             </DialogContentText>
//                             <TextField
//                                 error={error ? true : false}
//                                 helperText={error}
//                                 margin="dense"
//                                 id="name"
//                                 label="Email Address"
//                                 type="email"
//                                 variant="outlined"
//                                 value={email}
//                                 fullWidth
//                                 onChange={handleChange}
//                             />
//                             { submitError &&
//                                 <p style={{ color: 'var(--error)', fontSize: '1.4rem' }}>Problem subscribing to newsletter; please try again.</p>
//                             }
//                         </DialogContent>
//                         <DialogActions>
//                             <Button tabIndex={0} onClick={handleClose}>
//                                 Cancel
//                             </Button>
//                             <Button tabIndex={0} disabled={email.length === 0} onClick={handleSubmit} color="primary">
//                                 Subscribe
//                             </Button>
//                         </DialogActions>
//                     </>
//                 }
//             </Dialog>
//         </ThemeProvider>
//     );
// }

export default function Layout() {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

    return (
    <>
        <div style={{ height: '60px', backgroundColor: 'white' }}></div>
        <BannerStyled>
            <div className="newsletter-can" style={{ position: 'relative', width: '100px', alignSelf: 'flex-end', marginLeft: '10px', flexGrow: 0, flexShrink: 0  }}>
                <StaticImage
                    src="../assets/images/newsletter_can.png" 
                    alt="Beer can"
                    width={200}
                    quality={90}
                    style={{ width: '100px', position: 'absolute', bottom: '0px' }}
                    placeholder="blurred"
                />
            </div>
            <div className="newsletter-text">
                <h1>Thirsty for more?</h1>
                <h2>Get the latest news and events delivered to your inbox.</h2>
            </div>
            <Button
                style={{
                    fontSize: 'var(--font-size)',
                    backgroundColor: 'var(--accent-red)',
                    textTransform: 'uppercase',
                    color: 'white',
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                    flexGrow: 0,
                    flexShrink: 0,
                    }}
                role="button"
                variant="contained"
                startIcon={<SendIcon style={{ maxWidth: '20px' }} />}
                onClick={handleClickOpenDialog}
            >
                <span className="newsletter-wide">Sign Up</span>
                <span className="newsletter-narrow">Newsletter Sign Up</span>
            </Button>
        </BannerStyled>
        <SubscribeModal open={openDialog} handleClose={handleCloseDialog} />
    </>
    );
}
