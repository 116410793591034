import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/images/hbc_logo_black.svg';

const DrawerStyled = styled.div`
  padding: 45px;
  color: var(--accent-green);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 20px 0px;
  }
  
  a {
    display: block;
    text-decoration: none;
    font-weight: medium;
    font-size: 1.6rem;
    color: var(--text-gray-dark);
    transition: color .1s linear;
    &:hover {
      color: var(--accent-green);
    }

    & .icon-social path {
      transition: all .1s linear;
    }

    & .icon-social:hover path {
      fill: var(--accent-green);
    }

  }

  .disabled-link {
    color: var(--text-gray-light);
    font-weight: medium;
    cursor: pointer;
    font-size: 1.6rem;
  }

  #close-menu {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 28px;
    color: var(--text-gray-dark);
    transition: color .1s linear;
    &:hover {
      color: var(--accent-green);
    }
  }

  /* To show social-media in menu */
  /*
  .social-media {
    position: absolute;
    display: inline-flex;
    gap: 30px;
    right: 20px;
  }
  */

`;

const BannerStyled = styled.div`
  display: none;
  justify-content: flex-end;
  background-color: var(--background-gray);
  height: 32px;
  padding: 0 45px;
  align-items: center;
  
  a {
    text-decoration: none;
    &:hover {
      color: var(--accent-green);
    }

    & .icon-social path {
      transition: all .1s linear;
    }

    & .icon-social:hover path {
      fill: var(--accent-green);
    }
  }

  .social-media {
    display: inline-flex;
    gap: 20px;
  }
  
  #banner-location {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--accent-green);
    gap: 4px;
    transition: color .1s linear;

    &:hover {
      color: var(--text-green-highlight);
    }

  }

  // 1025px or less (desktop/laptop)
  @media (max-width: 1025px) {
    display: ${props => props.sticky ? 'none' : 'flex'};
  }

  // 600px or less (tablet/mobile) (switch to hamburger button)
  @media (max-width: 600px) {
    display: ${props => props.sticky ? 'none' : 'flex'};
    justify-content: center;
    /* To remove socials from the banner */
    /*
    justify-content: flex-end;
    .banner-social-icon {
      display: none;
    }
    */
  }

`

const HeaderStyled = styled.header`
  --font-size: 1.4rem;
  --height: 120px;
  --logo-width: 125px;
  width: 100%;
  font-size: var(--font-size);
  height: var(--height);

  #logo img {
    width: var(--logo-width);
  }

  #menu {
    display: none;
  }

  & > div {
    height: var(--height);
    background-color: white;
    transition: box-shadow 250ms;
    z-index: 100;
    width: 100%;

    ${props => props.sticky && `
      box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
      position: fixed;
      top: 0;
      left: 0;
    `}

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
      max-width: 1690px;
      margin: 0 auto;
      padding: 0px 45px;
      box-sizing: border-box;
      justify-content: space-between;
    }
  }

  a {
    transition: color .1s linear;
    color: var(--text-gray-dark);
    text-decoration: none;
    font-weight: bold;
    white-space: nowrap;

    &[aria-current="page"] {
      color: var(--accent-green);
    }

    &:hover {
      color: var(--accent-green);
    }

    & .icon-social path {
      transition: all .1s linear;
    }

    & .icon-social:hover path {
      fill: var(--accent-green);
    }

  }

  .social-media {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .disabled-link {
    color: var(--text-gray-light);
    font-weight: bold;
    cursor: pointer;
  }

  #more {
    vertical-align: middle;
    color: var(--text-gray-dark);
    transition: color .1s linear;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;

    .arrow {
      vertical-align: middle;
      transform: translateY(-1px);
    }

  }

  #more:hover {
    color: var(--accent-green)
  }

  // 1025px or less (desktop/laptop)
  @media (max-width: 1025px) {
    --height: 100px;
    --font-size: 1.2rem;
    --logo-width: 110px;
    
    .social-media {
      display: none;
    }
    
    #dividing-line {
      display: none;
    }

    #where {
      display: none;
    }

  }

  // 600px or less (tablet/mobile) (switch to hamburger button)
  @media (max-width: 600px) {
    --logo-width: 100px;
    --height: 80px;
    --font-size: 11px;

    justify-content: center;

    #menu {
      display: block;
      order: 0;
      color: var(--text-gray-dark);
    }

    #logo {
      order: 1;
    }

    #cart {
      order: 2;
    }
    
    #products, #taproom, #events, #about, #shop, #more, #more-menu {
      display: none;
    }

    #follow {
      margin-top: 10px;
    }

  }
`;

const IconStyled = styled.span`
  color: var(--text-gray-light);
  cursor: pointer;

  &:not([data-disabled="true"]):hover {
    color: var(--text-green-highlight);
  }

`;

const TooltipStyled = styled.div`
  font-size: 1.4rem;
  padding: 4px;
`

const theme = createTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 10,
  },
  palette: {
      primary: {
          main: '#42855E',
      },
      secondary: {
          main: '#AFAFAF',
      }
  }
});

export default function Nav() {
  const [sticky, setSticky] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMore, setOpenMore] = useState(null);

  useEffect(() => {
    window.onscroll = () => {
      setSticky(window.pageYOffset > 0);
    }
  }, []);

  const toggleMenu = toggle => {
    setOpenMenu(toggle);
  }

  const openMoreMenu = event => {
    setOpenMore(event.currentTarget);
  }

  const closeMoreMenu = () => {
    setOpenMore(null);
  }

  return (
    <div style={{ flexGrow: 0, flexShrink: 0 }}>
      <Drawer anchor="top" open={openMenu} onClose={() => toggleMenu(false)}>
        <DrawerStyled>
            <div role="button" id="close-menu" tabIndex={0} className="material-icons" onKeyDown={event => ['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key) && toggleMenu(false)} onClick={() => toggleMenu(false)}>close</div>
            <ul>
              <li>
                <Link onClick={() => toggleMenu(false)} to="/products">
                  Products
                </Link>
              </li>
              <li>
                <Link onClick={() => toggleMenu(false)} to="/taproom">
                  Taproom &amp; Menu
                </Link>
              </li>
              <li>
                <Link onClick={() => toggleMenu(false)} to="/events">
                  Events
                </Link>
              </li>
              <li>
                <Link onClick={() => toggleMenu(false)} to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link onClick={() => toggleMenu(false)} to="/where">
                  Where To Buy
                </Link>
              </li>
              <li>
                <Link onClick={() => toggleMenu(false)} to="/faq">
                 Frequently Asked Questions
                </Link>
              </li>
              {/* <li>
                <Link onClick={() => toggleMenu(false)} to="/covid">
                  COVID Info
                </Link>
              </li> */}
              <li>
                <Tooltip enterTouchDelay={1} placement="right" title={<TooltipStyled>Still brewing!</TooltipStyled>} aria-label="Coming Soon">
                  <span id="shop" className="disabled-link arrow">Shop</span>
                </Tooltip>
              </li>
            </ul>
            {/* Grouped Social Media */}
            {/*
            <div className="social-media">
              <a href="https://www.instagram.com/hamptonbrewingco/" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                  <g transform="translate(-0.449 0)">
                    <path id="Path_19" data-name="Path 19" d="M30.372,8.822a10.964,10.964,0,0,0-.7-3.64A7.686,7.686,0,0,0,25.284.791a10.992,10.992,0,0,0-3.64-.7C20.038.018,19.528,0,15.454,0S10.871.018,9.27.088a10.967,10.967,0,0,0-3.64.7A7.321,7.321,0,0,0,2.969,2.521,7.388,7.388,0,0,0,1.24,5.176a10.994,10.994,0,0,0-.7,3.64c-.076,1.606-.094,2.116-.094,6.19S.466,19.59.536,21.19a10.964,10.964,0,0,0,.7,3.64,7.685,7.685,0,0,0,4.39,4.39,10.994,10.994,0,0,0,3.64.7c1.6.071,2.11.088,6.184.088s4.584-.017,6.184-.088a10.962,10.962,0,0,0,3.64-.7,7.675,7.675,0,0,0,4.391-4.39,11,11,0,0,0,.7-3.64c.07-1.6.088-2.11.088-6.184s-.006-4.584-.076-6.184Zm-2.7,12.251a8.225,8.225,0,0,1-.516,2.784,4.977,4.977,0,0,1-2.849,2.849,8.254,8.254,0,0,1-2.784.516c-1.583.071-2.057.088-6.061.088s-4.484-.017-6.061-.088a8.22,8.22,0,0,1-2.784-.516,4.617,4.617,0,0,1-1.723-1.12,4.665,4.665,0,0,1-1.12-1.723,8.255,8.255,0,0,1-.516-2.784c-.071-1.583-.088-2.058-.088-6.061s.017-4.484.088-6.061a8.22,8.22,0,0,1,.516-2.784A4.561,4.561,0,0,1,4.9,4.449,4.658,4.658,0,0,1,6.621,3.33a8.259,8.259,0,0,1,2.784-.516c1.583-.07,2.058-.088,6.061-.088s4.484.018,6.061.088a8.224,8.224,0,0,1,2.784.516,4.614,4.614,0,0,1,1.723,1.119,4.664,4.664,0,0,1,1.12,1.723,8.258,8.258,0,0,1,.516,2.784c.07,1.583.088,2.057.088,6.061s-.018,4.472-.088,6.055Zm0,0" transform="translate(0 0)" fill="#423f3f" />
                    <path id="Path_20" data-name="Path 20" d="M133.777,124.5a8.828,8.828,0,1,0,8.828,8.828A8.83,8.83,0,0,0,133.777,124.5Zm0,14.555a5.727,5.727,0,1,1,5.726-5.727A5.727,5.727,0,0,1,133.777,139.055Zm0,0" transform="translate(-118.323 -118.323)" fill="#423f3f" />
                    <path id="Path_21" data-name="Path 21" d="M365.5,90.125a1.523,1.523,0,1,1-1.523-1.523A1.523,1.523,0,0,1,365.5,90.125Zm0,0" transform="translate(-340.338 -83.3)" fill="#423f3f" />
                  </g>
                </svg>
              </a>
              <a href="https://facebook.com/HamptonBrewingCo/" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                  <path d="M25.605,0H4.395A4.4,4.4,0,0,0,0,4.395V25.605A4.4,4.4,0,0,0,4.395,30h8.848V19.395H9.727V14.121h3.516V10.547a5.279,5.279,0,0,1,5.273-5.273h5.332v5.273H18.516v3.574h5.332l-.879,5.273H18.516V30h7.09A4.4,4.4,0,0,0,30,25.605V4.395A4.4,4.4,0,0,0,25.605,0Zm0,0" fill="#423f3f" />
                </svg>
              </a>
              <a href="https://untappd.com/HamptonBrewingCo" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" role="img" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 13.299l-5.824 8.133c-.298.416-.8.635-1.308.572-.578-.072-1.374-.289-2.195-.879S.392 19.849.139 19.323a1.402 1.402 0 0 1 .122-1.425l5.824-8.133a3.066 3.066 0 0 1 1.062-.927l1.146-.604c.23-.121.436-.283.608-.478.556-.631 2.049-2.284 4.696-4.957l.046-.212a.134.134 0 0 1 .096-.1l.146-.037a.135.135 0 0 0 .101-.141l-.015-.18a.13.13 0 0 1 .125-.142c.176-.005.518.046 1.001.393s.64.656.692.824a.13.13 0 0 1-.095.164l-.175.044a.133.133 0 0 0-.101.141l.012.15a.131.131 0 0 1-.063.123l-.186.112c-1.679 3.369-2.764 5.316-3.183 6.046a2.157 2.157 0 0 0-.257.73l-.205 1.281A3.074 3.074 0 0 1 11 13.3zm12.739 4.598l-5.824-8.133a3.066 3.066 0 0 0-1.062-.927l-1.146-.605a2.138 2.138 0 0 1-.608-.478 50.504 50.504 0 0 0-.587-.654.089.089 0 0 0-.142.018 97.261 97.261 0 0 1-1.745 3.223 1.42 1.42 0 0 0-.171.485 3.518 3.518 0 0 0 0 1.103l.01.064c.075.471.259.918.536 1.305l5.824 8.133c.296.413.79.635 1.294.574a4.759 4.759 0 0 0 2.209-.881 4.762 4.762 0 0 0 1.533-1.802 1.4 1.4 0 0 0-.122-1.425zM8.306 3.366l.175.044a.134.134 0 0 1 .101.141l-.012.15a.13.13 0 0 0 .063.123l.186.112c.311.623.599 1.194.869 1.721.026.051.091.06.129.019.437-.469.964-1.025 1.585-1.668a.137.137 0 0 0 .003-.19c-.315-.322-.645-.659-1.002-1.02l-.046-.212a.13.13 0 0 0-.096-.099l-.146-.037a.135.135 0 0 1-.101-.141l.015-.18a.13.13 0 0 0-.123-.142c-.175-.005-.518.045-1.002.393-.483.347-.64.656-.692.824a.13.13 0 0 0 .095.164z" fill="#423F3F"/>
                </svg>
              </a>
            </div>
            */}
        </DrawerStyled>
      </Drawer>
      <BannerStyled id="banner" sticky={sticky}>
        {/* Grouped Social Media */}
        <div className="social-media">
          <Link id="banner-location" to="/where">
            <span className="material-icons" style={{ fontSize: '18px', transform: 'translateY(-1px)' }}>place</span>
            WHERE TO BUY
          </Link>
          <a className="banner-social-icon" href="https://www.instagram.com/hamptonbrewingco/" target="_blank" rel="noreferrer noopener">
            <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30">
              <g transform="translate(-0.449 0)">
                <path id="Path_19" data-name="Path 19" d="M30.372,8.822a10.964,10.964,0,0,0-.7-3.64A7.686,7.686,0,0,0,25.284.791a10.992,10.992,0,0,0-3.64-.7C20.038.018,19.528,0,15.454,0S10.871.018,9.27.088a10.967,10.967,0,0,0-3.64.7A7.321,7.321,0,0,0,2.969,2.521,7.388,7.388,0,0,0,1.24,5.176a10.994,10.994,0,0,0-.7,3.64c-.076,1.606-.094,2.116-.094,6.19S.466,19.59.536,21.19a10.964,10.964,0,0,0,.7,3.64,7.685,7.685,0,0,0,4.39,4.39,10.994,10.994,0,0,0,3.64.7c1.6.071,2.11.088,6.184.088s4.584-.017,6.184-.088a10.962,10.962,0,0,0,3.64-.7,7.675,7.675,0,0,0,4.391-4.39,11,11,0,0,0,.7-3.64c.07-1.6.088-2.11.088-6.184s-.006-4.584-.076-6.184Zm-2.7,12.251a8.225,8.225,0,0,1-.516,2.784,4.977,4.977,0,0,1-2.849,2.849,8.254,8.254,0,0,1-2.784.516c-1.583.071-2.057.088-6.061.088s-4.484-.017-6.061-.088a8.22,8.22,0,0,1-2.784-.516,4.617,4.617,0,0,1-1.723-1.12,4.665,4.665,0,0,1-1.12-1.723,8.255,8.255,0,0,1-.516-2.784c-.071-1.583-.088-2.058-.088-6.061s.017-4.484.088-6.061a8.22,8.22,0,0,1,.516-2.784A4.561,4.561,0,0,1,4.9,4.449,4.658,4.658,0,0,1,6.621,3.33a8.259,8.259,0,0,1,2.784-.516c1.583-.07,2.058-.088,6.061-.088s4.484.018,6.061.088a8.224,8.224,0,0,1,2.784.516,4.614,4.614,0,0,1,1.723,1.119,4.664,4.664,0,0,1,1.12,1.723,8.258,8.258,0,0,1,.516,2.784c.07,1.583.088,2.057.088,6.061s-.018,4.472-.088,6.055Zm0,0" transform="translate(0 0)" fill="#423f3f" />
                <path id="Path_20" data-name="Path 20" d="M133.777,124.5a8.828,8.828,0,1,0,8.828,8.828A8.83,8.83,0,0,0,133.777,124.5Zm0,14.555a5.727,5.727,0,1,1,5.726-5.727A5.727,5.727,0,0,1,133.777,139.055Zm0,0" transform="translate(-118.323 -118.323)" fill="#423f3f" />
                <path id="Path_21" data-name="Path 21" d="M365.5,90.125a1.523,1.523,0,1,1-1.523-1.523A1.523,1.523,0,0,1,365.5,90.125Zm0,0" transform="translate(-340.338 -83.3)" fill="#423f3f" />
              </g>
            </svg>
          </a>
          <a className="banner-social-icon" href="https://facebook.com/HamptonBrewingCo/" target="_blank" rel="noreferrer noopener">
            <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30">
              <path d="M25.605,0H4.395A4.4,4.4,0,0,0,0,4.395V25.605A4.4,4.4,0,0,0,4.395,30h8.848V19.395H9.727V14.121h3.516V10.547a5.279,5.279,0,0,1,5.273-5.273h5.332v5.273H18.516v3.574h5.332l-.879,5.273H18.516V30h7.09A4.4,4.4,0,0,0,30,25.605V4.395A4.4,4.4,0,0,0,25.605,0Zm0,0" fill="#423f3f" />
            </svg>
          </a>
          <a className="banner-social-icon" href="https://untappd.com/HamptonBrewingCo" target="_blank" rel="noreferrer noopener">
            <svg className="icon-social" role="img" width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 13.299l-5.824 8.133c-.298.416-.8.635-1.308.572-.578-.072-1.374-.289-2.195-.879S.392 19.849.139 19.323a1.402 1.402 0 0 1 .122-1.425l5.824-8.133a3.066 3.066 0 0 1 1.062-.927l1.146-.604c.23-.121.436-.283.608-.478.556-.631 2.049-2.284 4.696-4.957l.046-.212a.134.134 0 0 1 .096-.1l.146-.037a.135.135 0 0 0 .101-.141l-.015-.18a.13.13 0 0 1 .125-.142c.176-.005.518.046 1.001.393s.64.656.692.824a.13.13 0 0 1-.095.164l-.175.044a.133.133 0 0 0-.101.141l.012.15a.131.131 0 0 1-.063.123l-.186.112c-1.679 3.369-2.764 5.316-3.183 6.046a2.157 2.157 0 0 0-.257.73l-.205 1.281A3.074 3.074 0 0 1 11 13.3zm12.739 4.598l-5.824-8.133a3.066 3.066 0 0 0-1.062-.927l-1.146-.605a2.138 2.138 0 0 1-.608-.478 50.504 50.504 0 0 0-.587-.654.089.089 0 0 0-.142.018 97.261 97.261 0 0 1-1.745 3.223 1.42 1.42 0 0 0-.171.485 3.518 3.518 0 0 0 0 1.103l.01.064c.075.471.259.918.536 1.305l5.824 8.133c.296.413.79.635 1.294.574a4.759 4.759 0 0 0 2.209-.881 4.762 4.762 0 0 0 1.533-1.802 1.4 1.4 0 0 0-.122-1.425zM8.306 3.366l.175.044a.134.134 0 0 1 .101.141l-.012.15a.13.13 0 0 0 .063.123l.186.112c.311.623.599 1.194.869 1.721.026.051.091.06.129.019.437-.469.964-1.025 1.585-1.668a.137.137 0 0 0 .003-.19c-.315-.322-.645-.659-1.002-1.02l-.046-.212a.13.13 0 0 0-.096-.099l-.146-.037a.135.135 0 0 1-.101-.141l.015-.18a.13.13 0 0 0-.123-.142c-.175-.005-.518.045-1.002.393-.483.347-.64.656-.692.824a.13.13 0 0 0 .095.164z" fill="#423F3F"/>
            </svg>
          </a>
        </div>
      </BannerStyled>
      <HeaderStyled sticky={sticky}>
        <div>
          <div>
            {/* Only shows when screen is below 600px */}
            <IconStyled role="button" id="menu" tabIndex={0} className="material-icons" onKeyDown={event => ['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key) && toggleMenu(true)} onClick={() => toggleMenu(true)}>menu</IconStyled>
            <Link id="logo" to="/">
              <img id="logo" src={logo} alt="Hampton Brewing Logo"></img>
            </Link>
            <Link id="products" to="/products">
              <span>Products</span>
            </Link>
            <Link id="taproom" to="/taproom">Taproom</Link>
            <Link id="events" to="/events">Events</Link>
            <Link id="about" to="/about">About Us</Link>
            <span onClick={openMoreMenu} id="more">More<span className="material-icons arrow">arrow_drop_down</span></span>
            <ThemeProvider theme={theme}>
              <Menu
                id="more-menu"
                anchorEl={openMore}
                keepMounted
                open={Boolean(openMore)}
                onClose={closeMoreMenu}
              >
                <MenuItem className="more-menu-item" onClick={closeMoreMenu}>
                  <Link to="/faq" style={{ textDecoration: 'none', color: 'var(--text-gray-dark)'}}>
                    Frequently Asked Questions
                  </Link>
                </MenuItem>
                {/* <MenuItem className="more-menu-item" onClick={closeMoreMenu}>
                  <Link to="/covid" style={{ textDecoration: 'none', color: 'var(--text-gray-dark)'}}>
                    COVID Info
                  </Link>
                </MenuItem> */}
              </Menu>
            </ThemeProvider>
            {/* <Tooltip enterTouchDelay={1} title={<TooltipStyled>Still brewing!</TooltipStyled>} aria-label="Coming Soon">
              <span id="shop" className="disabled-link">Shop</span>
            </Tooltip> */}
            <Tooltip enterTouchDelay={1} title={<TooltipStyled>Still brewing!</TooltipStyled>} aria-label="Coming Soon">
              <IconStyled id="cart" className="material-icons" data-disabled>shopping_cart</IconStyled>
            </Tooltip>
            {/* Dividing Line */}
            <div id="dividing-line" style={{
              height: '40px',
              width: '2px',
              backgroundColor: 'var(--accent-green)',
              flexShrink: 0,
            }}>
            </div>
            {/* TODO: This might be better as a modal */}
            <Link id="where" to="/where">
              <Button
                style={{
                  fontSize: 'var(--font-size)',
                  backgroundColor: 'var(--accent-green)',
                  textTransform: 'uppercase',
                  color: 'white',
                  whiteSpace: 'nowrap',
                }}
                variant="contained"
                role="button"
              >Where to buy</Button>
            </Link>
            {/* Grouped Social Media */}
            <div className="social-media">
              <a href="https://www.instagram.com/hamptonbrewingco/" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                  <g transform="translate(-0.449 0)">
                    <path id="Path_19" data-name="Path 19" d="M30.372,8.822a10.964,10.964,0,0,0-.7-3.64A7.686,7.686,0,0,0,25.284.791a10.992,10.992,0,0,0-3.64-.7C20.038.018,19.528,0,15.454,0S10.871.018,9.27.088a10.967,10.967,0,0,0-3.64.7A7.321,7.321,0,0,0,2.969,2.521,7.388,7.388,0,0,0,1.24,5.176a10.994,10.994,0,0,0-.7,3.64c-.076,1.606-.094,2.116-.094,6.19S.466,19.59.536,21.19a10.964,10.964,0,0,0,.7,3.64,7.685,7.685,0,0,0,4.39,4.39,10.994,10.994,0,0,0,3.64.7c1.6.071,2.11.088,6.184.088s4.584-.017,6.184-.088a10.962,10.962,0,0,0,3.64-.7,7.675,7.675,0,0,0,4.391-4.39,11,11,0,0,0,.7-3.64c.07-1.6.088-2.11.088-6.184s-.006-4.584-.076-6.184Zm-2.7,12.251a8.225,8.225,0,0,1-.516,2.784,4.977,4.977,0,0,1-2.849,2.849,8.254,8.254,0,0,1-2.784.516c-1.583.071-2.057.088-6.061.088s-4.484-.017-6.061-.088a8.22,8.22,0,0,1-2.784-.516,4.617,4.617,0,0,1-1.723-1.12,4.665,4.665,0,0,1-1.12-1.723,8.255,8.255,0,0,1-.516-2.784c-.071-1.583-.088-2.058-.088-6.061s.017-4.484.088-6.061a8.22,8.22,0,0,1,.516-2.784A4.561,4.561,0,0,1,4.9,4.449,4.658,4.658,0,0,1,6.621,3.33a8.259,8.259,0,0,1,2.784-.516c1.583-.07,2.058-.088,6.061-.088s4.484.018,6.061.088a8.224,8.224,0,0,1,2.784.516,4.614,4.614,0,0,1,1.723,1.119,4.664,4.664,0,0,1,1.12,1.723,8.258,8.258,0,0,1,.516,2.784c.07,1.583.088,2.057.088,6.061s-.018,4.472-.088,6.055Zm0,0" transform="translate(0 0)" fill="#423f3f" />
                    <path id="Path_20" data-name="Path 20" d="M133.777,124.5a8.828,8.828,0,1,0,8.828,8.828A8.83,8.83,0,0,0,133.777,124.5Zm0,14.555a5.727,5.727,0,1,1,5.726-5.727A5.727,5.727,0,0,1,133.777,139.055Zm0,0" transform="translate(-118.323 -118.323)" fill="#423f3f" />
                    <path id="Path_21" data-name="Path 21" d="M365.5,90.125a1.523,1.523,0,1,1-1.523-1.523A1.523,1.523,0,0,1,365.5,90.125Zm0,0" transform="translate(-340.338 -83.3)" fill="#423f3f" />
                  </g>
                </svg>
              </a>
              <a href="https://facebook.com/HamptonBrewingCo/" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                  <path d="M25.605,0H4.395A4.4,4.4,0,0,0,0,4.395V25.605A4.4,4.4,0,0,0,4.395,30h8.848V19.395H9.727V14.121h3.516V10.547a5.279,5.279,0,0,1,5.273-5.273h5.332v5.273H18.516v3.574h5.332l-.879,5.273H18.516V30h7.09A4.4,4.4,0,0,0,30,25.605V4.395A4.4,4.4,0,0,0,25.605,0Zm0,0" fill="#423f3f" />
                </svg>
              </a>
              <a href="https://untappd.com/HamptonBrewingCo" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" role="img" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 13.299l-5.824 8.133c-.298.416-.8.635-1.308.572-.578-.072-1.374-.289-2.195-.879S.392 19.849.139 19.323a1.402 1.402 0 0 1 .122-1.425l5.824-8.133a3.066 3.066 0 0 1 1.062-.927l1.146-.604c.23-.121.436-.283.608-.478.556-.631 2.049-2.284 4.696-4.957l.046-.212a.134.134 0 0 1 .096-.1l.146-.037a.135.135 0 0 0 .101-.141l-.015-.18a.13.13 0 0 1 .125-.142c.176-.005.518.046 1.001.393s.64.656.692.824a.13.13 0 0 1-.095.164l-.175.044a.133.133 0 0 0-.101.141l.012.15a.131.131 0 0 1-.063.123l-.186.112c-1.679 3.369-2.764 5.316-3.183 6.046a2.157 2.157 0 0 0-.257.73l-.205 1.281A3.074 3.074 0 0 1 11 13.3zm12.739 4.598l-5.824-8.133a3.066 3.066 0 0 0-1.062-.927l-1.146-.605a2.138 2.138 0 0 1-.608-.478 50.504 50.504 0 0 0-.587-.654.089.089 0 0 0-.142.018 97.261 97.261 0 0 1-1.745 3.223 1.42 1.42 0 0 0-.171.485 3.518 3.518 0 0 0 0 1.103l.01.064c.075.471.259.918.536 1.305l5.824 8.133c.296.413.79.635 1.294.574a4.759 4.759 0 0 0 2.209-.881 4.762 4.762 0 0 0 1.533-1.802 1.4 1.4 0 0 0-.122-1.425zM8.306 3.366l.175.044a.134.134 0 0 1 .101.141l-.012.15a.13.13 0 0 0 .063.123l.186.112c.311.623.599 1.194.869 1.721.026.051.091.06.129.019.437-.469.964-1.025 1.585-1.668a.137.137 0 0 0 .003-.19c-.315-.322-.645-.659-1.002-1.02l-.046-.212a.13.13 0 0 0-.096-.099l-.146-.037a.135.135 0 0 1-.101-.141l.015-.18a.13.13 0 0 0-.123-.142c-.175-.005-.518.045-1.002.393-.483.347-.64.656-.692.824a.13.13 0 0 0 .095.164z" fill="#423F3F"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </HeaderStyled>
    </div>
  );
}
