import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    :root {
        --accent-red: #853B35;
        --accent-green: #42855E;
        --accent-purple: #61678C;
        --text-gray-dark: #423F3F;
        --text-gray-light: #AFAFAF;
        --background-gray: #F8F8F8;
        --background-dark-gray: #222422;
        --background-green: #21372A;
        --text-green: var(--accent-green);
        --text-green-highlight: #98cdae;
        --black: 900;
        --bold: 700;
        --medium: 500;
        --light: 300;
        --thin: 100;
        --error: #f44336;
        --notice-background: #ffecb3;
        --notice-border: #fbab18;
        --horizontal-rule: #D2DEDE;
    }

    /* Need the header to go the width of the screen but for the content not to expand more than 1920 */
    body {
        margin: 0;
    }

    .gatsby-image-wrapper img[${'src*=base64\\,'}] {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }

    html {
        font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 10px;
    }
`;

export default GlobalStyles;
