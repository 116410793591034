import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'gatsby';
import styled from 'styled-components';
import useHasMounted from '../hooks/useHasMounted';

const ContentStyled = styled.div`
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: 500px;
  z-index: 9999;

  a {
    text-decoration: none;
  }

  h2 {
    margin-top: 0px;
  }

  p {
    font-size: 1.4rem;
    margin: 0;
  }

  @media (max-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

`;

const theme = createTheme({
    typography: {
      // Tell Material-UI what the font-size on the html element is.
      htmlFontSize: 10,
    },
    palette: {
        primary: {
            main: '#42855E',
        },
        secondary: {
            main: '#AFAFAF',
        }
    }
  }
);

export default function GDPRDialog({ closeDialog }) {
  const hasMounted = useHasMounted();

  const acceptCookies = () => {
    if (hasMounted) {
      const gaDisable = `ga-disable-${process.env.GATSBY_GA_MEASUREMENT_ID}`;
      window.localStorage.setItem('gdrp-optout', 'false');
      window[gaDisable] = false;
    }
    closeDialog();
  };

  return (
    <ContentStyled>
      <ThemeProvider theme={theme}>
        <Card>
          <CardContent>
            <h2>We Use Cookies</h2>
            <p style={{ fontSize: '1.6rem' }}>
              Hi, this website uses cookies to enhance site navigation and analyze
              site usage. By clicking "Accept" you agree to the storing of cookies
              on your device.
            </p>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              role="button"
              variant="contained"
              startIcon={<Icon>cookie</Icon>}
              onClick={acceptCookies}
            >
              Accept
            </Button>
            <Link to="/privacy">
              <Button onClick={closeDialog}>
                Learn More
              </Button>
            </Link>
          </CardActions>
        </Card>
      </ThemeProvider>
    </ContentStyled>
  )
}
