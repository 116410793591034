import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import hamptonLogo from '../assets/images/hampton_logo.png';
import ourBeerText from '../assets/images/its_our_beer_text_white.svg';
import { useStaticQuery, graphql } from 'gatsby';

const FooterStyled = styled.footer`
  min-height: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--background-green);
  color: var(--text-gray-light);
  font-size: 1.2rem;

  #faq a:not(:first-child) {
    margin-top: 12px;
  }

  .footer-copyright {
    max-width: 1690px;
    margin: 0 auto;
    padding: 30px 45px;
    box-sizing: border-box;

    a {
      font-size: 1.2rem;
      display: inline;
    }

    ul {
      text-align: center;
      list-style: none;
      margin: 0;
      padding: 0;

      & li {
        display: inline-block;
        margin: 0px 5px;
      }
      // 1025px or less (desktop/laptop)
      @media (max-width: 1025px) {
        li.footer-copyright-pipe {
          display: none;
        }
        li:not(.footer-copyright-pipe) {
          margin: 4px 0px;
          display: block;
        }
      }

    }
  }

  .footer-content {
    display: flex;
    align-items: top;
    max-width: 1690px;
    margin: 0 auto;
    padding: 60px 45px 0px 45px;
    box-sizing: border-box;
    justify-content: space-between;

    // 1025px or less (desktop/laptop)
    @media (max-width: 1025px) {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-around;
      row-gap: 20px;

      #brewery-info {
        grid-row: 1;
        grid-column: 1;
      }

      #hours {
        grid-row: 1;
        grid-column: 2;
      }

      #faq {
        grid-column: 1;
        grid-row: 2 / 4;
        line-height: 18px;
      }
      
      #proudly {
        grid-row: 2;
        grid-column: 2;
        text-align: center;
        min-width: 180px;
        margin-top: 20px;
      }

      #follow-header, #proudly-header {
        display: none;
      }

      #follow {
        grid-row: 3;
        grid-column: 2;
      }

      #follow > div {
        text-align: center;
      }

    }

    // 600px or less (tablet/mobile)
    @media (max-width: 600px) {
      grid-template-columns: auto;
      padding: 60px 30px 0px 30px;

      #brewery-info {
        grid-row: 1;
        grid-column: 1;
      }

      #hours {
        grid-row: 2;
        grid-column: 1;
      }

      #faq {
        grid-row: 3;
        grid-column: 1;
      }
      
      #proudly {
        margin-top: 20px;
        grid-row: 4;
        grid-column: 1;
      }

      #follow {
        grid-row: 5;
        grid-column: 1;
        margin-top: 20px;
      }
    }

  }

  & a .icon-social path {
    transition: all .1s linear;
  }

  & a .icon-social:hover path {
      fill: var(--accent-green);
  }

  & a {
    transition: color .1s linear;
    font-weight: var(--medium);
    color: var(--accent-green);
    font-size: 1.4rem;
    text-decoration: none;
    margin-top: ${props => props.marginTop ? props.marginTop : 0}px;
    display: block;

    &:hover {
      color: var(--text-green-highlight);
    }
  }

  & a.subtle-link {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

`;

const HeaderStyled = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: white;
  margin-bottom: 22px;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  white-space: nowrap;
`;

const SubheaderStyled = styled.div`
  font-weight: var(--medium);
  color: var(--accent-green);
  font-size: 1.4rem;
  margin-top: ${props => props.marginTop ? props.marginTop : 0}px;
`;

export default function Footer() {
  const { faq, hours } = useStaticQuery(graphql`
    query {
      faq: allSanityFaq(filter: {onFooter: {eq: true }}) {
        nodes {
          _id
          question
          answer
        }
      }
      hours: allSanityHour {
        nodes {
          dow
          day
          isOpen
          open
          close
        }
      }
    }
  `);

  return (
    <FooterStyled>
      <div className="footer-content">
        <div id="brewery-info">
            <HeaderStyled>Brewery Info</HeaderStyled>
              <SubheaderStyled>
                Contact
              </SubheaderStyled>
              <p style={{ lineHeight: '17px' }}>
                <a className="subtle-link" href="tel:5068324364" rel="noreferrer noopener">
                  506.832.4364
                </a>
                <a className="subtle-link" href="mailto:info@hamptonbrewingco.ca" rel="noreferrer noopener">
                  info@hamptonbrewingco.ca
                </a>
              </p>
            <a className="subtle-link" href="https://goo.gl/maps/gAhXyyyicJPfguze8" target="_blank" rel="noreferrer noopener">
              <SubheaderStyled>
                Address
              </SubheaderStyled>
              <p style={{ lineHeight: '17px' }}>64 Robertson Rd <br /> Hampton, NB E5N 6N8</p>
            </a>
        </div>
        <div id="hours">
            {/* TODO: This needs to be pulled from the CMS */}
            <HeaderStyled>Taproom Hours</HeaderStyled>
            <div style={{
              display: 'grid',
              rowGap: '6px',
              columnGap: '12px',
            }}>
            {hours.nodes.sort((a, b) => a.dow - b.dow).map((d, i) => 
              <React.Fragment key={d.day}>
                <div style={{ gridColumn: 1, gridRow: i + 1 }}>{d.day}</div>
                <div style={{ gridColumn: 2, gridRow: i + 1, textAlign: 'right' }}>{!d.isOpen ? 'Closed' : `${d.open} - ${d.close}`}</div>
              </React.Fragment>
            )}
            </div>
            {/* <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>*Hours subject to change</p> */}
        </div>
        <div id="follow">
            <HeaderStyled id="follow-header">Follow Hampton Brewing</HeaderStyled>
            <div style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}>
              <a href="https://www.instagram.com/hamptonbrewingco/" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                  <g transform="translate(-0.449 0)">
                    <path id="Path_19" data-name="Path 19" d="M30.372,8.822a10.964,10.964,0,0,0-.7-3.64A7.686,7.686,0,0,0,25.284.791a10.992,10.992,0,0,0-3.64-.7C20.038.018,19.528,0,15.454,0S10.871.018,9.27.088a10.967,10.967,0,0,0-3.64.7A7.321,7.321,0,0,0,2.969,2.521,7.388,7.388,0,0,0,1.24,5.176a10.994,10.994,0,0,0-.7,3.64c-.076,1.606-.094,2.116-.094,6.19S.466,19.59.536,21.19a10.964,10.964,0,0,0,.7,3.64,7.685,7.685,0,0,0,4.39,4.39,10.994,10.994,0,0,0,3.64.7c1.6.071,2.11.088,6.184.088s4.584-.017,6.184-.088a10.962,10.962,0,0,0,3.64-.7,7.675,7.675,0,0,0,4.391-4.39,11,11,0,0,0,.7-3.64c.07-1.6.088-2.11.088-6.184s-.006-4.584-.076-6.184Zm-2.7,12.251a8.225,8.225,0,0,1-.516,2.784,4.977,4.977,0,0,1-2.849,2.849,8.254,8.254,0,0,1-2.784.516c-1.583.071-2.057.088-6.061.088s-4.484-.017-6.061-.088a8.22,8.22,0,0,1-2.784-.516,4.617,4.617,0,0,1-1.723-1.12,4.665,4.665,0,0,1-1.12-1.723,8.255,8.255,0,0,1-.516-2.784c-.071-1.583-.088-2.058-.088-6.061s.017-4.484.088-6.061a8.22,8.22,0,0,1,.516-2.784A4.561,4.561,0,0,1,4.9,4.449,4.658,4.658,0,0,1,6.621,3.33a8.259,8.259,0,0,1,2.784-.516c1.583-.07,2.058-.088,6.061-.088s4.484.018,6.061.088a8.224,8.224,0,0,1,2.784.516,4.614,4.614,0,0,1,1.723,1.119,4.664,4.664,0,0,1,1.12,1.723,8.258,8.258,0,0,1,.516,2.784c.07,1.583.088,2.057.088,6.061s-.018,4.472-.088,6.055Zm0,0" transform="translate(0 0)" fill="#FFFFFF" />
                    <path id="Path_20" data-name="Path 20" d="M133.777,124.5a8.828,8.828,0,1,0,8.828,8.828A8.83,8.83,0,0,0,133.777,124.5Zm0,14.555a5.727,5.727,0,1,1,5.726-5.727A5.727,5.727,0,0,1,133.777,139.055Zm0,0" transform="translate(-118.323 -118.323)" fill="#FFFFFF" />
                    <path id="Path_21" data-name="Path 21" d="M365.5,90.125a1.523,1.523,0,1,1-1.523-1.523A1.523,1.523,0,0,1,365.5,90.125Zm0,0" transform="translate(-340.338 -83.3)" fill="#FFFFFF" />
                  </g>
                </svg>
              </a>
              <a href="https://facebook.com/HamptonBrewingCo/" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                  <path d="M25.605,0H4.395A4.4,4.4,0,0,0,0,4.395V25.605A4.4,4.4,0,0,0,4.395,30h8.848V19.395H9.727V14.121h3.516V10.547a5.279,5.279,0,0,1,5.273-5.273h5.332v5.273H18.516v3.574h5.332l-.879,5.273H18.516V30h7.09A4.4,4.4,0,0,0,30,25.605V4.395A4.4,4.4,0,0,0,25.605,0Zm0,0" fill="#FFFFFF" />
                </svg>
              </a>
              <a href="https://untappd.com/HamptonBrewingCo" target="_blank" rel="noreferrer noopener">
                <svg className="icon-social" role="img" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title/>
                    <path d="M11 13.299l-5.824 8.133c-.298.416-.8.635-1.308.572-.578-.072-1.374-.289-2.195-.879S.392 19.849.139 19.323a1.402 1.402 0 0 1 .122-1.425l5.824-8.133a3.066 3.066 0 0 1 1.062-.927l1.146-.604c.23-.121.436-.283.608-.478.556-.631 2.049-2.284 4.696-4.957l.046-.212a.134.134 0 0 1 .096-.1l.146-.037a.135.135 0 0 0 .101-.141l-.015-.18a.13.13 0 0 1 .125-.142c.176-.005.518.046 1.001.393s.64.656.692.824a.13.13 0 0 1-.095.164l-.175.044a.133.133 0 0 0-.101.141l.012.15a.131.131 0 0 1-.063.123l-.186.112c-1.679 3.369-2.764 5.316-3.183 6.046a2.157 2.157 0 0 0-.257.73l-.205 1.281A3.074 3.074 0 0 1 11 13.3zm12.739 4.598l-5.824-8.133a3.066 3.066 0 0 0-1.062-.927l-1.146-.605a2.138 2.138 0 0 1-.608-.478 50.504 50.504 0 0 0-.587-.654.089.089 0 0 0-.142.018 97.261 97.261 0 0 1-1.745 3.223 1.42 1.42 0 0 0-.171.485 3.518 3.518 0 0 0 0 1.103l.01.064c.075.471.259.918.536 1.305l5.824 8.133c.296.413.79.635 1.294.574a4.759 4.759 0 0 0 2.209-.881 4.762 4.762 0 0 0 1.533-1.802 1.4 1.4 0 0 0-.122-1.425zM8.306 3.366l.175.044a.134.134 0 0 1 .101.141l-.012.15a.13.13 0 0 0 .063.123l.186.112c.311.623.599 1.194.869 1.721.026.051.091.06.129.019.437-.469.964-1.025 1.585-1.668a.137.137 0 0 0 .003-.19c-.315-.322-.645-.659-1.002-1.02l-.046-.212a.13.13 0 0 0-.096-.099l-.146-.037a.135.135 0 0 1-.101-.141l.015-.18a.13.13 0 0 0-.123-.142c-.175-.005-.518.045-1.002.393-.483.347-.64.656-.692.824a.13.13 0 0 0 .095.164z" fill="#FFFFFF"/>
                </svg>
              </a>
            </div>
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <img src={ourBeerText} alt="It's Our Beer Text" width="100" />
            </div>
        </div>
        <div id="faq">
            <HeaderStyled>FAQ</HeaderStyled>
            {faq.nodes.map(d => <Link key={`faq#${d._id}`} to={`/faq#${d._id}`}>{d.question}</Link>)}
            {/* <Link key={`faq#more`} to={`/faq`}>...more...</Link> */}
        </div>
        <div id="proudly">
            <HeaderStyled id="proudly-header" textAlign="center">Proudly</HeaderStyled>
            <a href="https://www.townofhampton.ca/" target="_blank" rel="noreferrer noopener">
              <img src={hamptonLogo} alt="Hampton Logo" width="100" />
            </a>
        </div>
      </div>
      <div className="footer-copyright">
        <ul>
            <li>© Hampton Brewing Co. {(new Date()).getFullYear()}</li>
            <li className="footer-copyright-pipe">|</li>
            <li><a href="https://www.linkedin.com/in/joshweston/" target="_blank" rel="noreferrer noopener">Website by JW</a></li>
            <li className="footer-copyright-pipe">|</li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            {/* <li className="footer-copyright-pipe">|</li>
            <li><Link to="/covid">Covid Policy</Link></li> */}
        </ul>
      </div>
    </FooterStyled>
  );
}
