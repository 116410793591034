import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import TypographyStyles from '../styles/Typography';
import useCopyright from '../hooks/useCopyright';
import useHasMounted from '../hooks/useHasMounted';
import NewsletterBanner from '../components/NewsletterBanner';
import { MainStyled } from '../styles/Components';
import GDPRDialog from './GDPRDialog';

export default function Layout({ children, path }) {
  // useCopyright();
  const hasMounted = useHasMounted();

  const [showGDRPDialog, setShowGDRPDialog] = useState(false);
  useEffect(() => {
      // If they have made a choice on data collection, we no longer show the pop-up
      if (path.includes('privacy')) {
        setShowGDRPDialog(false);
      } else if (hasMounted && window.localStorage.getItem('gdrp-optout')) {
        setShowGDRPDialog(false);
      } else {
        setShowGDRPDialog(true);
      }
  }, [hasMounted]);

  const showBanner = !path.includes('newsletter');

  // Close the banner after the user makes a decision
  const closeDialog = () => {
    setShowGDRPDialog(false);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <GlobalStyles />
      <TypographyStyles />
      <Header />
      <MainStyled>
        {children}
      </MainStyled>
      { showBanner && <NewsletterBanner /> }
      { showGDRPDialog && <GDPRDialog closeDialog={closeDialog} />}
      <Footer />
    </div>
  );
}
